<template>
  <SplitPage v-if="authorizations.length">
    <template #left-top>
      <BackToAuthorizationsIndex/>
    </template>
    <template #left-content>
      <div class="table-responsive">
        <table class="table">
          <tbody>
          <tr v-for="(authorization, index) in authorizations"
              :key="authorization[0].id"
              class="authorization">
            <td>
              <input type="checkbox"
                     v-model="authorizations[index][3]" />
            </td>
            <td>
              <div class="authorization-cities">
                {{ authorization[0].city_start }} <br />
                {{ authorization[0].city_end }}
              </div>
              <div class="authorization-nums">
                {{ authorization[0].id }} /
                {{ authorization[0].num_authorization_on_pdf }}
              </div>
              <div class="authorization-dates">
                {{ formatDate(authorization[0].date_start) }} -
                {{ formatDate(authorization[0].date_end) }}
              </div>
            </td>
            <td>
              <div class="authorization-status">
                <AuthorizationStatusBadge :authorization="authorization[0]"
                                          class="small-status-badge"/>
              </div>
            </td>
            <td>
              <div class="authorization-pdf">
                <i @click="getPdf(authorization[0])"
                   class="icon-PDF"></i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template #right-content>
      <div class="authorization-map">
        <LMap ref="map"
              id="map"
              :options="map.options"
              :bounds="bounds">
          <LTileLayer :url="map.url"
                      attribution=""/>
          <LControlZoom position="topright"/>
          <LGeoJson v-for="(authorization, index) in authorizations"
                    :key="index"
                    :geojson="authorization[1]"
                    :options-style="authorization[2]"
                    :visible="authorizations[index][3]" />
        </LMap>
      </div>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import BackToAuthorizationsIndex from '@/components/BackToAuthorizationsIndex'
import AuthorizationStatusBadge from '@/components/AuthorizationStatusBadge'
import { LMap, LTileLayer, LControlZoom, LGeoJson } from 'vue2-leaflet'
import axios from 'axios'
import { getAuthorization, getAuthorizationPdf, getAuthorizationSteps, extractAuthorizationGeojson } from '@/api/premat'
import { formatDate, stepToGeoJson, bboxToBounds } from '@/utils'
import geojsonMerge from '@mapbox/geojson-merge'
import $ from 'jquery'

export default {
  name: 'AuthorizationsVisualizePage',
  components: {
    SplitPage,
    BackToAuthorizationsIndex,
    AuthorizationStatusBadge,
    LMap,
    LTileLayer,
    LControlZoom,
    LGeoJson
  },
  data () {
    return {
      authorizations: [],
      map: {
        url: process.env.VUE_APP_TILE_SERVER_URL,
        options: {
          zoomSnap: 0.5,
          zoomControl: false,
          attributionControl: false
        }
      }
    }
  },
  computed: {
    bounds () {
      let minx = 360.0
      let maxx = -360.0
      let miny = 360.0
      let maxy = -360.0
      this.authorizations.forEach(item => {
        const auth = item[0]
        console.log(auth)
        const geojson = JSON.parse(auth.geojson_way_0)
        geojson.features.forEach(feature => {
          minx = Math.min(minx, feature.bbox[0])
          maxx = Math.max(maxx, feature.bbox[3])
          miny = Math.min(miny, feature.bbox[1])
          maxy = Math.max(maxy, feature.bbox[4])
        })
      })
      return bboxToBounds([minx, miny, maxx, maxy])
    }
  },
  methods: {
    getAuthorizations () {
      let selected = []
      if (this.$route.query.authorizations === undefined) {
        // missing "authorizations" query param, we redirect back
        this.$router.push({ name: 'authorizations.index' })
      } else if (typeof this.$route.query.authorizations === 'string') {
        if (this.$route.query.authorizations.length === 0) {
          // empty "authorizations" query param, we redirect back
          this.$router.push({ name: 'authorizations.index' })
        } else {
          // only one "authorizations" query param
          selected = [this.$route.query.authorizations]
        }
      } else {
        // multiple "authorizations" query params
        selected = this.$route.query.authorizations
      }
      const requests = []
      const authorizations = []
      selected.forEach(id => {
        requests.push(getAuthorization(id))
      })
      Promise.all(requests).then((responses) => {
        responses.forEach(response => {
          const auth = response.data.authorization
          let color = '#2cbdff'
          if (auth.status === 'ongoing input') {
            color = '#9e2cff'
          } else if (auth.status === 'waiting for validation') {
            color = '#788da3'
          } else if (auth.status === 'validated') {
            color = '#2cbdff'
          } else if (auth.status === 'not valid'
                     || auth.status === 'deleted') {
            color = '#f9124f'
          } else if (auth.status === 'to be renewed') {
            color = '#ff8a2c'
          } else if (auth.status === 'waiting for renewal') {
            color = '#ffdf2c'
          }
          authorizations.push([
            auth,
            extractAuthorizationGeojson(auth, 0),
            { color: color, weight: 7 },
            true
          ])
        })
      }).then(() => {
        this.authorizations = authorizations
      })
    },
    formatDate (date) {
      return formatDate(date)
    },
    getPdf (authorization) {
      getAuthorizationPdf(authorization)
    }
  },
  mounted () {
    this.getAuthorizations()
  }
}
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 20px;

    tr td:first-child {
      padding-left: 0;
      padding-right: 5px;
    }
  }

  .authorization-cities {
    font-size: 13px;

    @media (max-width: $breakpoint-lg) {
      font-size: 12px;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
    }
  }

  .authorization-nums,
  .authorization-dates,
  .small-status-badge {
    font-size: 12px;

    @media (max-width: $breakpoint-lg) {
      font-size: 11px;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 10px;
    }
  }

  .authorization-cities {
    font-weight: $font-weight-bold;
  }

  .icon-PDF {
    color: $red;
    font-size: 34px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $breakpoint-lg) {
      font-size: 30px;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 24px;
    }
  }

  .authorization-map {
    height: 100%;
  }

  #map {
    height: 100%;
    width: 100%;
  }
</style>
